<template>
	<div class="home-wrapper">
		<v-container>
			<v-row>
				<v-col cols="12">
					<h2>Payment History Lookup</h2>
				</v-col>
				<v-col cols="12">
				<v-text-field
					label="Launcher ID"
					placeholder="Launcher ID"
					variant="outlined"
					v-model="launcherID"
				/>
				<div class="actions d-flex justify-space-between">
					<v-btn
					color="primary"
					@click="getData"
					>
					Get Payments
					</v-btn>
					<vue-json-to-csv 
						v-if="totalResults"
						:json-data="exportData">
					<v-btn
						:disabled="!exportData.length"
					>export to csv</v-btn>
					</vue-json-to-csv>
				</div>
				
				</v-col>
				<v-col cols="12">
					<v-data-table
						v-if="totalResults"
						:headers="headers"
						:items="exportData"
						:items-per-page="20"
						class="elevation-1"
						:loading="loading"
					></v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import axios from 'axios'
import VueJsonToCsv from 'vue-json-to-csv'

export default {
	name: "LookupForm",
	data: () => ({
		result: '',
		launcherID: '',
		columns: [],
		rows: [],
		loading: false,
		totalResults: [],
		headers: [
			{ text: 'height', value: 'height' },
			{ text: 'amount in xch', value: 'amount' },
			{ text: 'transaction id', value: 'transaction_id' },
			{ text: 'price in usd', value: 'xch_price' },
			{ text: 'date', value: 'datetime' },
		],
	}),
	components: {
    VueJsonToCsv
	},
	computed: {
		url(){
			return 'https://spacefarmers.io/api/farmers/' + this.launcherID + '/payouts'
		},
// 61eb9796c98a93d9f47f20b0bc0dd31f72f5c8838fbc140e59a5f70062fd6c4a
		exportData(){

		let data = []
		if(this.totalResults) {
			data = this.totalResults.map(item => {
			let selectedData = {}
			selectedData.id = item.id
			selectedData.height = item.attributes.height
			selectedData.amount = (item.attributes.amount / (10 ** 12)) + ' XCH'
			selectedData.transaction_id = item.attributes.transaction_id
			selectedData.xch_price = '$' + item.attributes.xch_price
			selectedData.datetime = item.attributes.datetime

			return selectedData
			})
		}

		return data
		}
	},
	methods: {
		async getData() {
			this.loading = true
			try{

				let res = await axios.get(this.url)
				this.totalResults = JSON.parse(JSON.stringify(res.data.data))
				while(res.data.links.next) {
					res = await axios.get(res.data.links.next)
					this.totalResults = this.totalResults.concat(res.data.data)
				}
			} catch (err) {
				console.log(err)
			}
			this.loading = false
		}
	}
}
</script>
<style lang="scss" scoped>

</style>